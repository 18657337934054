import {Action, CombinedState} from 'redux';
import {ReduxState, RootThunkDispatch, ThunkResult} from '../reducers';
import {appError, appLogin, appLogout, Login} from '../reducers/appReducer';
import {httpFetch} from '../lib/httpFetch';
import {handleJsonResponse, isJson} from '.';
import {parseToken} from '../lib/token';
import {getConfigVariable} from '../lib/getConfig';
import {resetAction} from '../reducers/common';
import {deleteCache} from '../lib/commonCache';
import {doNotificationUnsubscribe, doNotificationSubscribe} from './notificationActions';
import {doReset} from './globalActions';

const tryFetchToken = async (urlencoded: URLSearchParams, retry: number): Promise<Response | undefined> => {
	let res: Response | undefined;
	const path = await getConfigVariable('API_SERVER', undefined, {
		useDefaults: true,
	});

	while (retry > 0) {
		const req = new Request(`${path}/connect/token`, {
			method: 'POST',
			body: urlencoded,
		});
		try {
			res = await httpFetch(req);

			// Retry if 500
			if (res.status === 500) {
				retry--;
				console.log('Server responsend 500 from token endpoint.');
				await new Promise((resolve) => setTimeout(resolve, 500));
				continue;
			}

			return res;
		} catch (err) {
			console.log(err);
			retry--;
			await new Promise((resolve) => setTimeout(resolve, 500));
			continue;
		}
		// ok...
		return res;
	}
	return res;
};

export const doLogin =
	(username: string, password: string, lang: string): ThunkResult<Promise<Action>> =>
	async (dispatch: RootThunkDispatch) => {
		dispatch(appError(undefined));
		try {
			const urlencoded = new URLSearchParams();
			urlencoded.append('username', username);
			urlencoded.append('password', password);
			urlencoded.append('grant_type', 'password');
			urlencoded.append('scope', 'offline_access');

			const res = await tryFetchToken(urlencoded, 3);

			const data = await dispatch(handleJsonResponse<Login>(res));
			if (data != null) {
				dispatch(doNotificationSubscribe(lang));
				return Promise.resolve(dispatch(appLogin(data)));
			} else if (res != null && res.status === 400) {
				// Wrong password
				throw new Error('Wrong password!');
			} else {
				throw new Error('Data was not received');
			}
		} catch (err) {
			dispatch(appError(err));
			return Promise.reject(err);
		}
	};

export const getAccessToken = async (accessToken?: string | null, refreshToken?: string | null): Promise<Login> => {
	let authToken = accessToken;
	let newRefreshToken = refreshToken;
	const token = parseToken(accessToken);

	if ((!token || token.exp < Date.now() / 1000 + 10) && refreshToken) {
		const urlencoded = new URLSearchParams();
		urlencoded.append('grant_type', 'refresh_token');
		urlencoded.append('refresh_token', refreshToken);
		urlencoded.append('scope', 'offline_access');

		const res = await tryFetchToken(urlencoded, 3);
		if (res) {
			const data = (await res.json()) as Login;
			authToken = data.access_token;
			newRefreshToken = data.refresh_token;
		}
	}
	if (!authToken || !newRefreshToken) {
		throw new Error('No access token or refresh token');
	}
	return {
		access_token: authToken,
		refresh_token: newRefreshToken,
	};
};

// save promise so we dont call api multiple times
let accessTokenPromise: any | undefined;
let accessTokenResolve: any | undefined;

export const DoAuth = (): ThunkResult<Promise<string>> => async (dispatch: RootThunkDispatch, getState: () => ReduxState) => {
	if (accessTokenPromise) {
		await accessTokenPromise;
	} else {
		accessTokenPromise = new Promise((resolve, reject) => {
			accessTokenResolve = resolve;
		});
	}
	const {
		app: {accessToken, refreshToken},
	} = getState();

	// acquire token
	let tokens: Login;
	try {
		tokens = await getAccessToken(accessToken, refreshToken);
	} catch (err) {
		dispatch(appLogout());
		if (accessTokenResolve) {
			accessTokenResolve();
			accessTokenResolve = undefined;
			accessTokenPromise = undefined;
		}
		throw err;
	}

	// save token if it was acquired
	if (tokens.access_token !== accessToken) {
		dispatch(appLogin(tokens));
	}

	if (accessTokenResolve) {
		accessTokenResolve();
		accessTokenResolve = undefined;
		accessTokenPromise = undefined;
	}
	return tokens.access_token;
};

export const doLogout = (): ThunkResult<Promise<Action>> => async (dispatch: RootThunkDispatch) => {
	dispatch(doNotificationUnsubscribe());
	dispatch(appLogout());
	dispatch(doReset());
	await deleteCache();
	return dispatch(resetAction());
};
