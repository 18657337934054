import React, {useState, createContext, useEffect, Dispatch, SetStateAction} from 'react';
import {useSelector, useThunkDispatch} from '../reducers';
import {getAckedAlarms, getUnAckedAlarms, alarmAcked} from '../actions/alarmActions';
import {HubConnection, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {DoAuth} from '../actions/appActions';
import {getConfigVariable} from '../lib/getConfig';

export const SignalRContext = createContext<ISignalRContext | null>(null);

interface ISignalRContext {
	alarms: IReceivedAlarm[];
	setAlarms: Dispatch<SetStateAction<IReceivedAlarm[]>>;
	setAlarmsSeen: (alarms: IReceivedAlarm[]) => void;
}

export interface IReceivedAlarm {
	customerId: number;
	eventN: number;
	sendCount: number;
	customerName: string;
	alarmName: string;
	soundType: number;
	eventTime: string;
}

function playSound(alarm: IReceivedAlarm) {
	const audios = document.querySelectorAll('audio');
	if (audios != null && alarm.soundType > 0) {
		try {
			audios[alarm.soundType - 1].play();
		} catch (e) {}
	}
}

const SignalRProvider: React.FC<{
	children?: React.ReactNode;
}> = ({children}) => {
	const dispatch = useThunkDispatch();
	const [alarms, setAlarms] = useState<IReceivedAlarm[]>([]);
	const [addAlarm, setAddAlarm] = useState<IReceivedAlarm | null>(null);
	const [signalRConnection, setSignalRConnection] = useState<HubConnection | null>(null);
	const {accessToken} = useSelector((state) => ({accessToken: state.app.accessToken}));

	useEffect(() => {
		let connection: HubConnection;
		const openSignalRConnection = async () => {
			const path = await getConfigVariable('API_SERVER', undefined, {
				useDefaults: true,
			});

			connection = new HubConnectionBuilder()
				.withUrl(`${path}/signalr`, {accessTokenFactory: async () => await dispatch(DoAuth())})
				.withAutomaticReconnect()
				.configureLogging(LogLevel.Information)
				.build();

			connection.on('alarms', (message: string) => {
				const messageData = JSON.parse(message);
				if (messageData.data) {
					setAddAlarm(messageData.data);
				}
			});

			connection.on('alarmAcked', (message: string) => {
				const messageData = JSON.parse(message);
				const alarm = messageData.data as IReceivedAlarm;
				if (alarm) {
					dispatch(alarmAcked(alarm.eventN)); // Remove alarm from redux
					setAlarms((alarms) => alarms.filter((a) => a.eventN !== alarm.eventN));
				}
			});

			connection
				.start()
				.then(() => setSignalRConnection(connection))
				.catch((err) => console.log('signalR: error', err));
		};

		if (accessToken) {
			openSignalRConnection();
		}

		return () => {
			connection?.stop();
		};
	}, [accessToken]);

	// This useEffect is used to filter alarms used in context.
	useEffect(() => {
		if (addAlarm != null) {
			// Check if alarm is already added
			if (alarms.find((a) => a.eventN === addAlarm.eventN)) {
				// Alarm is already in context. If this is a resend play sound.
				if (alarms.find((a) => a.eventN === addAlarm.eventN && a.sendCount === addAlarm.sendCount)) return;

				// seems to be a new send -> play alarm and exit
				playSound(addAlarm);
				return;
			}

			// A new alarm first refreash state.
			dispatch(getAckedAlarms());
			dispatch(getUnAckedAlarms());

			// play sound
			playSound(addAlarm);

			// Clear added alarm and update context.
			setAddAlarm(null);
			setAlarms((alarms) => [...alarms, addAlarm]);
		}
	}, [addAlarm, alarms]);

	const setAlarmsSeen = (alarms: IReceivedAlarm[]) => {
		for (const alarm of alarms) {
			const customerId = alarm.customerId;
			const eventNumber = alarm.eventN;
			const customerName = alarm.customerName;
			const alarmName = alarm.alarmName;
			signalRConnection?.invoke('SetAlarmAsSeen', customerId, eventNumber, customerName, alarmName);
		}
	};

	const signalRValue: ISignalRContext = {
		alarms,
		setAlarms,
		setAlarmsSeen,
	};

	return (
		<>
			<SignalRContext.Provider value={signalRValue}>{children}</SignalRContext.Provider>;
		</>
	);
};

export default SignalRProvider;
