export const primary = {
	100: '#F6F9FC',
	200: '#E6F5FC',
	300: '#B3E2F5',
	400: '#1AA8E2',
	500: '#009EDF',
	600: '#008EC9',
	700: '#007EB2',
	800: '#005F86',
	900: '#004F70',
};
export const AGreen = {
	100: '#FAFBED',
	200: '#F0F3C8',
	300: '#E7ECA3',
	400: '#CED846',
	500: '#B9C23F',
	600: '#03703C',
	700: '#676C23',
	800: '#52561C',
	900: '#292B0E',
};
export const AYellow = {
	100: '#FFF5CE',
	200: '#FFE89E',
	300: '#FFD76D',
	400: '#FFC749',
	500: '#FFAD0D',
	600: '#DB8C09',
	700: '#B76F06',
	800: '#935404',
	900: '#7A4102',
};
export const red = {
	100: '#FFE3D6',
	200: '#FFC0AD',
	300: '#FF9783',
	400: '#FF6F65',
	500: '#FF3236',
	600: '#DB2438',
	700: '#B71938',
	800: '#930F35',
	900: '#7A0933',
};

export const lightGray = {
	100: '#FAFAFB',
	200: '#F5F6F7',
	300: '#EEEFF2',
	400: '#E2E4E8',
	500: '#CACDD5',
	600: '#B2B7C2',
	700: '#A4A9B6',
	800: '#959CAB',
	900: '#8C93A3',
};

export const ADarkGray = {
	100: '#747C90',
	200: '#656E85',
	300: '#5C657D',
	400: '#525C76',
	500: '#49536E',
	600: '#3A4662',
	700: '#2C3857',
	800: '#192648',
	900: '#091E42',
};

export const APurple = {
	100: '#FBFAFF',
	200: '#ECE8FF',
	300: '#DCD5FF',
	400: '#A673EF',
	500: '#8247E5',
	600: '#6433C4',
	700: '#4A23A4',
	800: '#331684',
	900: '#220D6D',
};
