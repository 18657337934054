import jwt from 'jwt-decode';
interface Token {
	exp: number;
}
export const parseToken = (token?: string | null): Token | undefined => {
	if (!token) {
		return undefined;
	}
	return jwt<Token>(token);
};
