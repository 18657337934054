import React, {useState, useContext, createContext} from 'react';

export const NavbarContext = createContext<['normal' | 'small', React.Dispatch<React.SetStateAction<'normal' | 'small'>>] | undefined>(undefined);

const NavbarProvider: React.FC<{
	children?: React.ReactNode;
}> = ({children}) => {
	const [navbarSize, setNavbarSize] = useState<'normal' | 'small'>('normal');
	return <NavbarContext.Provider value={[navbarSize, setNavbarSize]}>{children}</NavbarContext.Provider>;
};

export default NavbarProvider;
