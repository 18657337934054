import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {IWellnessShortResponse, IWellnessLongResponse} from '../actions/customerWellnessActions';
import {buildSliceConfig, getKey, migrateInit} from '../lib/persistUtils';
import {resetAction} from './common';
/**
 * Redux state interface
 */
interface IState {
	wellnessLongTerm: IWellnessLongResponse[];
	wellnessShortTerm: IWellnessShortResponse[];
	_persist: any;
}

/**
 * Initial redux state
 */
const initialState: IState = {
	wellnessLongTerm: [],
	wellnessShortTerm: [],
	_persist: undefined,
};

const slice = createSlice({
	name: 'wellness',
	initialState,
	reducers: {
		setWellnessLongTerm: (state, action: PayloadAction<IWellnessLongResponse>) => {
			state.wellnessLongTerm[action.payload.customerId] = action.payload;
		},
		setWellnessShortTerm: (state, action: PayloadAction<IWellnessShortResponse>) => {
			state.wellnessShortTerm[action.payload.customerId] = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAction, () => initialState);
	},
});

export const {setWellnessLongTerm, setWellnessShortTerm} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(
	slice,
	persistReducer(
		{
			key: getKey(slice.name),
			storage,
			migrate: migrateInit(initialState),
		},
		slice.reducer,
	),
);
