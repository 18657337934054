import {persistReducer} from 'redux-persist';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {buildSliceConfig, getKey, migrateInit} from '../lib/persistUtils';
import {resetAction} from './common';
import * as jwt_decode from 'jwt-decode';

/**
 * Redux state interface
 */
interface IState {
	error: string | undefined;
	isLoading: boolean;
	isLoggedIn: boolean;
	accessToken: string | null;
	refreshToken: string | null;
	username: string | null;
	role: string | null;
	_persist: any;
}

/**
 * Initial redux state
 */
const initialState: IState = {
	error: undefined,
	isLoading: false,
	isLoggedIn: false,
	accessToken: null,
	refreshToken: null,
	username: null,
	role: null,
	_persist: undefined,
};
export interface Login {
	access_token: string;
	refresh_token: string;
}

interface jwt {
	name: string;
	role: string;
}

const slice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		appLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		appLogin: (state, action: PayloadAction<Login>) => {
			state.accessToken = action.payload.access_token;
			state.refreshToken = action.payload.refresh_token;
			const jwt: jwt = jwt_decode.default(action.payload.access_token);
			state.username = jwt.name;
			state.role = jwt.role;
			state.isLoggedIn = true;
		},
		appLogout: (state) => {
			state.isLoggedIn = false;
			state.accessToken = null;
			state.refreshToken = null;
			state.username = null;
			state.role = null;
		},
		appError: (state, action: PayloadAction<unknown>) => {
			if (!action.payload) {
				state.error = undefined;
				return;
			}
			if (typeof action.payload === 'string') {
				state.error = action.payload;
				return;
			}
			if (typeof action.payload === 'object' && action.payload instanceof Error) {
				state.error = action.payload.message;
				return;
			}
			state.error = `${action.payload}`;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAction, () => initialState);
	},
});

export const {appLoading, appLogin, appError, appLogout} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(
	slice,
	persistReducer(
		{
			key: getKey(slice.name),
			storage,
			blacklist: ['isLoading', 'error'],
			migrate: migrateInit(initialState),
		},
		slice.reducer,
	),
);
