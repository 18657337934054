import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {INurseEvent, INurseVisitType} from '../models/nurseEvent';
import {buildSliceConfig} from '../lib/persistUtils';
import {resetAction} from './common';
/**
 * Redux state interface
 */
interface IEventState {
	events: Record<number, INurseEvent[]>;
	visitTypes?: INurseVisitType[];
	_persist: any;
}

/**
 * Initial redux state
 */
const initialState: IEventState = {
	events: [],
	visitTypes: undefined,
	_persist: undefined,
};

const slice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		setNurseEvents: (state, action: PayloadAction<{customerId: number; events: INurseEvent[]}>) => {
			const {customerId, events} = action.payload;
			state.events[customerId] = events;
		},
		setNurseVisitTypes: (state, action: PayloadAction<INurseVisitType[]>) => {
			state.visitTypes = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAction, () => initialState);
	},
});

export const {setNurseEvents, setNurseVisitTypes} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(slice);
