import {ThemeOptions} from '@mui/material';
import {primary, AGreen, red, lightGray, ADarkGray, AYellow} from './colors';

const theme: ThemeOptions = {
	typography: {
		fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
		fontWeightRegular: 400,
		fontWeightBold: 600,
		fontSize: 14,

		h1: {
			fontSize: '3.5rem',
			lineHeight: '140%',
		},
		h2: {
			fontSize: '2.625rem',
			lineHeight: '150%',
		},
		h3: {
			fontSize: '2.25rem',
			lineHeight: '150%',
		},
		h4: {
			fontSize: '2rem',
			lineHeight: '150%',
		},
		h5: {
			fontSize: '1.75rem',
			lineHeight: '150%',
		},
		h6: {
			fontSize: '1.5rem',
			lineHeight: '150%',
		},
		subtitle1: {
			fontSize: '1.25rem',
			lineHeight: '160%',
		},
		subtitle2: {
			fontSize: '1.125rem',
			lineHeight: '160%',
		},
		body1: {
			fontSize: '1rem',
			lineHeight: '160%',
		},
		body2: {
			fontSize: '0.875rem', // 14px
			lineHeight: '160%',
		},
	},
	palette: {
		primary: {
			main: primary[500],
			// dark: primary[600],
		},
		/*	secondary: { // this must be decided later on
			main: primary[100],
		}, */
		success: {
			main: AGreen[300],
			dark: AGreen[400],
			contrastText: AGreen[700],
		},
		error: {
			main: red[100],
			contrastText: red[700],
		},
		warning: {
			main: AYellow[100],
			contrastText: AYellow[800],
		},
		background: {
			default: '#FFFFFF',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					padding: '8px 14px',
				},
				sizeLarge: {
					height: '40px',
					fontSize: '16px',
					fontWeight: 600,
					letterSpacing: '0.15px',
					lineHeight: '16.8px',
				},
				sizeSmall: {
					height: '32px',
					fontSize: '14px',
					fontWeight: 'bold',
					letterSpacing: '0.15px',
					lineHeight: '19.2px',
				},
			},
		},
	},
};
export default theme;
