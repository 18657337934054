import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IResponseData, ICurveParsed, IWellbeingSleep, IWellbeingInfo} from '../actions/customerCurvesActions';
import {buildSliceConfig} from '../lib/persistUtils';
import {resetAction} from './common';
import moment from 'moment';

/**
 * Redux state interface
 */

interface IStateCurve {
	curve1: IStateCurveData;
	curve2: IStateCurveData;
	curve3: IStateCurveData;
	curve4: IStateCurveData;
	curve5: IStateCurveData;
}

export interface IStateCurveData {
	data: Array<ICurveParsed>;
	sleep: IWellbeingSleep | null;
	info: IWellbeingInfo | null;
	dateFrom: string;
	dateTo: string;
}

interface IState {
	curves: Array<IStateCurve>;
	_persist: any;
}

/**
 * Initial redux state
 */
const initialState: IState = {
	curves: [],
	_persist: undefined,
};

const pushData = (dataEntry: any, j: any, curves: any, firstEntryTimestamp: any) => {
	const statusLineStart = -5;
	const statusLineEnd = -1.5;
	const time = (dataEntry.values[j][0] - 1) * 1000 * 60 + firstEntryTimestamp;
	const value = dataEntry.values[j][1];

	const sleep = dataEntry.sleep;
	const yellow = dataEntry.flag === 'yellow';
	const red = dataEntry.flag === 'red';
	curves.push({
		x: time,
		wake: !sleep ? value : null,
		sleep: sleep ? value : null,
		yellow: yellow ? value : null,
		red: red ? value : null,
		y_sleep: sleep ? [statusLineStart, statusLineEnd] : null,
		y_yellow: yellow ? [statusLineStart, statusLineEnd] : null,
		y_red: red ? [statusLineStart, statusLineEnd] : null,
	});
};

const createCurveData = (curveData: IResponseData) => {
	const curves: ICurveParsed[] = [];

	curveData.curves.forEach((c: any) => {
		// Set time to midnight
		const dateStr = c.date.substring(0, c.date.indexOf('T'));
		const date = moment(dateStr, 'YYYY-MM-DD').toLocaleString();
		const firstEntryTimestamp = Number(moment(date).format('x'));

		for (let i = 0; i < c.data.length; i++) {
			const dataEntry = c.data[i];
			if (dataEntry.values == null) continue;
			for (let j = 0; j < dataEntry.values.length; j++) {
				pushData(dataEntry, j, curves, firstEntryTimestamp);
			}
		}
	});
	return curves;
};

const slice = createSlice({
	name: 'curves',
	initialState,
	reducers: {
		setCurveData: (state, action: PayloadAction<IResponseData>) => {
			const curves = createCurveData(action.payload);
			console.log(action.payload);
			state.curves[action.payload.customerId] = {
				curve1: {data: [], sleep: null, info: null, dateFrom: '', dateTo: ''},
				curve2: {data: [], sleep: null, info: null, dateFrom: '', dateTo: ''},
				curve3: {data: [], sleep: null, info: null, dateFrom: '', dateTo: ''},
				curve4: {data: [], sleep: null, info: null, dateFrom: '', dateTo: ''},
				curve5: {data: [], sleep: null, info: null, dateFrom: '', dateTo: ''},
			};

			state.curves[action.payload.customerId].curve1.data = curves.slice(720, 2161);
			state.curves[action.payload.customerId].curve2.data = curves.slice(2160, 3601);
			state.curves[action.payload.customerId].curve3.data = curves.slice(3600, 5041);
			state.curves[action.payload.customerId].curve4.data = curves.slice(5040, 6481);
			state.curves[action.payload.customerId].curve5.data = curves.slice(6480, 7921);

			state.curves[action.payload.customerId].curve1.info = action.payload.curves[0].WellbeingInfo;
			state.curves[action.payload.customerId].curve2.info = action.payload.curves[1].WellbeingInfo;
			state.curves[action.payload.customerId].curve3.info = action.payload.curves[2].WellbeingInfo;
			state.curves[action.payload.customerId].curve4.info = action.payload.curves[3].WellbeingInfo;
			state.curves[action.payload.customerId].curve5.info = action.payload.curves[4].WellbeingInfo;

			state.curves[action.payload.customerId].curve1.sleep = action.payload.curves[0].WellbeingSleep;
			state.curves[action.payload.customerId].curve2.sleep = action.payload.curves[1].WellbeingSleep;
			state.curves[action.payload.customerId].curve3.sleep = action.payload.curves[2].WellbeingSleep;
			state.curves[action.payload.customerId].curve4.sleep = action.payload.curves[3].WellbeingSleep;
			state.curves[action.payload.customerId].curve5.sleep = action.payload.curves[4].WellbeingSleep;

			const format = 'DD-MM-YYYY';

			const dateFromCurve1 = moment(action.payload.curves[0].date);
			const dateFromCurve2 = moment(action.payload.curves[1].date);
			const dateFromCurve3 = moment(action.payload.curves[2].date);
			const dateFromCurve4 = moment(action.payload.curves[3].date);
			const dateFromCurve5 = moment(action.payload.curves[4].date);

			state.curves[action.payload.customerId].curve1.dateFrom = dateFromCurve1.format(format);
			state.curves[action.payload.customerId].curve2.dateFrom = dateFromCurve2.format(format);
			state.curves[action.payload.customerId].curve3.dateFrom = dateFromCurve3.format(format);
			state.curves[action.payload.customerId].curve4.dateFrom = dateFromCurve4.format(format);
			state.curves[action.payload.customerId].curve5.dateFrom = dateFromCurve5.format(format);

			state.curves[action.payload.customerId].curve1.dateTo = dateFromCurve1.add(1, 'days').format(format);
			state.curves[action.payload.customerId].curve2.dateTo = dateFromCurve2.add(1, 'days').format(format);
			state.curves[action.payload.customerId].curve3.dateTo = dateFromCurve3.add(1, 'days').format(format);
			state.curves[action.payload.customerId].curve4.dateTo = dateFromCurve4.add(1, 'days').format(format);
			state.curves[action.payload.customerId].curve5.dateTo = dateFromCurve5.add(1, 'days').format(format);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAction, () => initialState);
	},
});

export const {setCurveData} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(slice);
