import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IAlarm, IAlarmCustomText, IAlarmType} from '../actions/alarmActions';
import {buildSliceConfig} from '../lib/persistUtils';
import ICustomerAlarm from '../models/customerAlarm';
import {resetAction} from './common';
/**
 * Redux state interface
 */
interface IState {
	ackedAlarms: IAlarm[];
	unAckedAlarms: IAlarm[];
	alarmTypes: IAlarmType[];
	alarmTexts: Record<number, IAlarmCustomText[]>;
	customerAlarms: Record<number, ICustomerAlarm[]>;
	_persist: any;
}

/**
 * Initial redux state
 */
const initialState: IState = {
	ackedAlarms: [],
	unAckedAlarms: [],
	alarmTypes: [],
	alarmTexts: {},
	customerAlarms: [],
	_persist: undefined,
};

const slice = createSlice({
	name: 'alarms',
	initialState,
	reducers: {
		setCustomerAlarms: (state, action: PayloadAction<{customerId: number; alarms: ICustomerAlarm[]}>) => {
			const {customerId, alarms} = action.payload;
			state.customerAlarms[customerId] = alarms;
		},
		setAckedAlarms: (state, action: PayloadAction<IAlarm[]>) => {
			state.ackedAlarms = action.payload;
		},
		setUnAckedAlarms: (state, action: PayloadAction<IAlarm[]>) => {
			state.unAckedAlarms = action.payload;
		},
		setAlarmTypes: (state, action: PayloadAction<IAlarmType[]>) => {
			state.alarmTypes = action.payload;
		},
		setAlarmTexts: (
			state,
			action: PayloadAction<{
				eventId: number;
				texts: IAlarmCustomText[];
			}>,
		) => {
			state.alarmTexts[action.payload.eventId] = action.payload.texts;
		},
		removeUnackedAlarm: (state, action: PayloadAction<number>) => {
			state.unAckedAlarms = state.unAckedAlarms.filter((alarm) => alarm.id !== action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAction, () => initialState);
	},
});

export const {setCustomerAlarms, setAckedAlarms, setUnAckedAlarms, setAlarmTypes, setAlarmTexts, removeUnackedAlarm} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(slice);
