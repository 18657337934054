import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {ICustomer} from '../actions/customerActions';
import {buildSliceConfig, getKey, migrateInit} from '../lib/persistUtils';
import {ICustomerDetail, ICustomerGroup} from '../models/customer';
import {resetAction} from './common';
/**
 * Redux state interface
 */

interface IStarredCustomer {
	customerId: number;
	starred: boolean;
}

interface IState {
	updating: boolean;
	updateFailedError: string | undefined;
	customers: ICustomer[];
	customerDetails: ICustomerDetail[];
	starred: IStarredCustomer[];
	customergroups: ICustomerGroup[];
	// custromerRecords: Record<string, ICustomerDetail>;
	_persist: any;
}

/**
 * Initial redux state
 */
const initialState: IState = {
	updating: false,
	updateFailedError: undefined,
	customers: [],
	customerDetails: [],
	starred: [],
	customergroups: [],
	_persist: undefined,
};

const slice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		setCustomers: (state, action: PayloadAction<ICustomer[]>) => {
			state.customers = action.payload;
		},
		setCustomerUpdating: (state, action: PayloadAction<boolean>) => {
			state.updating = action.payload;
		},
		setCustomerUpdateError: (state, action: PayloadAction<string | undefined>) => {
			state.updating = false;
			state.updateFailedError = action.payload;
		},
		setCustomer: (state, action: PayloadAction<ICustomerDetail>) => {
			let found = false;
			state.customerDetails.forEach((customer: ICustomerDetail, index: number) => {
				if (customer.id === action.payload.id) {
					found = true;
					state.customerDetails[index] = action.payload;
				}
			});
			if (!found) {
				state.customerDetails.push(action.payload);
			}
		},
		setStarred: (state, action: PayloadAction<IStarredCustomer>) => {
			let found = false;
			state.starred.map((s) => {
				if (s.customerId === action.payload.customerId) {
					s.starred = action.payload.starred;
					found = true;
				}
				return s;
			});
			if (!found) state.starred.push(action.payload);
		},
		setCustomerGroups: (state, action: PayloadAction<ICustomerGroup[]>) => {
			state.customergroups = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAction, () => initialState);
	},
});

export const {setCustomers, setCustomer, setStarred, setCustomerGroups, setCustomerUpdating, setCustomerUpdateError} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(
	slice,
	persistReducer(
		{
			key: getKey(slice.name),
			storage,
			migrate: migrateInit(initialState),
		},
		slice.reducer,
	),
);
