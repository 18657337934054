import {HttpClient} from 'mharj-http-client';
import {appLoading} from '../reducers/appReducer';
import {storeConfiguration} from '../configureStore';
import {getConfigVariable} from './getConfig';
import {DoAuth} from '../actions/appActions';
import {RootThunkDispatch} from '../reducers';

const client = HttpClient.getInstance();

client.onLoading((isLoading) => {
	storeConfiguration.store.dispatch(appLoading(isLoading));
});

export const httpFetch = process.env.NODE_ENV === 'test' ? fetch : client.fetch;

export const createRequest = async (dispatch: RootThunkDispatch, requestPath: string, method = 'GET', body?: string): Promise<Request> => {
	const headers = new Headers();
	const token = await dispatch(DoAuth());
	headers.set('Authorization', `Bearer ${token}`);
	headers.set('content-type', 'application/json');
	const path = await getConfigVariable('API_SERVER', undefined, {
		useDefaults: true,
	});

	return new Request(`${path}/${requestPath}`, {headers, method, body});
};

export const createPutRequest = async (dispatch: RootThunkDispatch, requestPath: string, body: string): Promise<Request> =>
	createRequest(dispatch, requestPath, 'PUT', body);

export const createPostRequest = async (dispatch: RootThunkDispatch, requestPath: string, body: string): Promise<Request> =>
	createRequest(dispatch, requestPath, 'POST', body);

export const createPatchRequest = async (dispatch: RootThunkDispatch, requestPath: string, body: string): Promise<Request> =>
	createRequest(dispatch, requestPath, 'PATCH', body);
