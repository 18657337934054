import {cacheMatch, cacheStore, isOnline} from './commonCache';
import {httpFetch} from './httpFetch';

type JsonValue = string | number | object | boolean | null;
type JsonRecord = Record<string, JsonValue>;

export interface Config {
	useDefaults?: boolean;
}

function stringWrap(value: JsonValue): string {
	switch (typeof value) {
		case 'boolean':
			return value ? 'true' : 'false';
		case 'number':
			return '' + value;
		case 'string':
			return value;
		default:
			throw new TypeError(`can't convert ${typeof value} to string`);
	}
}

let loadPromise: Promise<JsonRecord | undefined> | undefined;
// this function is readable
// eslint-disable-next-line sonarjs/cognitive-complexity
export async function loadService(): Promise<JsonRecord | undefined> {
	if (!loadPromise) {
		// we wanna use async and it doesnt matter if we lose the error here
		// eslint-disable-next-line no-async-promise-executor
		loadPromise = new Promise(async (resolve) => {
			const req = new Request('./settings.json');
			let res = await cacheMatch(req, {ifNoneMatch: true});
			const cacheData = res ? await res.json() : undefined;
			if (isOnline()) {
				res = await httpFetch(req);
				const contentType = res.headers.get('content-type');
				let data: any;
				if (res.status === 200 && contentType && contentType.includes('application/json')) {
					await cacheStore(req, res);
					data = await res.json();
				}
				if (data) {
					return resolve(data);
				} else if (cacheData) {
					return resolve(cacheData);
				}
			} else {
				if (cacheData) {
					return resolve(cacheData);
				}
			}
			return resolve(undefined);
		});
	}
	return loadPromise;
}

export const defaults: Record<string, string> = {
	API_SERVER: 'http://host.docker.internal',
};

let serviceCache: JsonRecord | undefined;
export async function getConfigVariable(name: string, defaultValue?: string | undefined, config?: Config | undefined): Promise<string | undefined> {
	if (process.env.NODE_ENV === 'test') {
		return defaults[name];
	}
	if (!serviceCache) {
		serviceCache = await loadService();
	}
	if (!serviceCache) {
		throw new Error(`no services.json loaded`);
	}
	const serviceEnv = serviceCache[name];
	if (serviceEnv) {
		return stringWrap(serviceEnv);
	}
	if (defaultValue) {
		return defaultValue;
	}
	const defaultVal = defaults[name];
	console.log(name, defaults);
	if (config?.useDefaults && defaultVal) {
		return defaultVal;
	}
	return undefined;
}
