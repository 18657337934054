import {handleJsonResponse, IPaginatedResponse} from '.';
import {cacheMatch, isOnline, cacheStore} from '../lib/commonCache';
import {getConfigVariable} from '../lib/getConfig';
import {httpFetch, createRequest} from '../lib/httpFetch';
import ICustomerAlarm from '../models/customerAlarm';
import {ThunkResult} from '../reducers';
import {setAckedAlarms, setAlarmTexts, setAlarmTypes, setUnAckedAlarms, removeUnackedAlarm, setCustomerAlarms} from '../reducers/alarmReducer';
import {appError, appLogout} from '../reducers/appReducer';
import {DoAuth} from './appActions';

export interface IAlarm {
	id: number;
	acked: number;
	eventTime: string;
	note: string;
	ackTime: string;
	actionWas: string;
	extraType: string;
	objName: string;
	locationName: string;
	type: number;
	groupName: string;
	customerId: number;
}
export interface IAlarmType {
	typen: number;
	name: string;
	text: string;
}

export interface IAlarmCustomText {
	texttype: number;
	customtextfin: string;
	customtexteng: string;
}

// thunk async functions
export const getAlarmTypes = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	dispatch(appError(undefined));
	try {
		const {
			alarms: {alarmTypes},
		} = getState();
		const headers = new Headers();
		const token = await dispatch(DoAuth());
		headers.set('Authorization', `Bearer ${token}`);
		const path = await getConfigVariable('API_SERVER', undefined, {
			useDefaults: true,
		});
		const req = new Request(`${path}/api/v1/alarmtype`, {headers});
		let res = await cacheMatch(req, {ifNoneMatch: !alarmTypes}); // check cached response (and update if-none-match to req if have etag)
		const cacheData = await dispatch(handleJsonResponse<IAlarmType[]>(res));
		if (isOnline()) {
			res = await httpFetch(req);
			await cacheStore(req, res);
			const data = await dispatch(handleJsonResponse<IAlarmType[]>(res, appLogout));
			if (data) {
				// we have new data
				dispatch(setAlarmTypes(data));
			} else if (cacheData) {
				// cached data still valid
				dispatch(setAlarmTypes(cacheData));
			}
		} else {
			if (cacheData) {
				// we are offline, use latest data from cache
				dispatch(setAlarmTypes(cacheData));
			}
		}
	} catch (err: unknown) {
		dispatch(appError(err));
		return Promise.reject(err);
	}
};

export const getAckedAlarms = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	dispatch(appError(undefined));
	try {
		const {
			alarms: {ackedAlarms},
		} = getState();
		const headers = new Headers();
		const token = await dispatch(DoAuth());
		headers.set('Authorization', `Bearer ${token}`);
		const path = await getConfigVariable('API_SERVER', undefined, {
			useDefaults: true,
		});
		const req = new Request(`${path}/api/v1/alarms/acked?top=1000`, {headers});
		let res = await cacheMatch(req, {ifNoneMatch: !ackedAlarms}); // check cached response (and update if-none-match to req if have etag)
		const cacheData = await dispatch(handleJsonResponse<IPaginatedResponse<IAlarm[]>>(res));
		if (isOnline()) {
			res = await httpFetch(req);
			await cacheStore(req, res);
			const data = await dispatch(handleJsonResponse<IPaginatedResponse<IAlarm[]>>(res, appLogout));
			if (data) {
				// we have new data
				dispatch(setAckedAlarms(data.value));
			} else if (cacheData) {
				// cached data still valid
				dispatch(setAckedAlarms(cacheData.value));
			}
		} else {
			if (cacheData) {
				// we are offline, use latest data from cache
				dispatch(setAckedAlarms(cacheData.value));
			}
		}
	} catch (err: unknown) {
		dispatch(appError(err));
		return Promise.reject(err);
	}
};

export const getUnAckedAlarms = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	dispatch(appError(undefined));
	try {
		const {
			alarms: {unAckedAlarms},
		} = getState();
		const headers = new Headers();
		const token = await dispatch(DoAuth());
		headers.set('Authorization', `Bearer ${token}`);
		const path = await getConfigVariable('API_SERVER', undefined, {
			useDefaults: true,
		});
		const req = new Request(`${path}/api/v1/alarms/unacked?top=100`, {headers});
		let res = await cacheMatch(req, {ifNoneMatch: !unAckedAlarms}); // check cached response (and update if-none-match to req if have etag)
		const cacheData = await dispatch(handleJsonResponse<IPaginatedResponse<IAlarm[]>>(res));
		if (isOnline()) {
			res = await httpFetch(req);
			await cacheStore(req, res);
			const data = await dispatch(handleJsonResponse<IPaginatedResponse<IAlarm[]>>(res, appLogout));
			if (data) {
				// we have new data
				dispatch(setUnAckedAlarms(data.value));
			} else if (cacheData) {
				// cached data still valid
				dispatch(setUnAckedAlarms(cacheData.value));
			}
		} else {
			if (cacheData) {
				// we are offline, use latest data from cache
				dispatch(setUnAckedAlarms(cacheData.value));
			}
		}
	} catch (err: unknown) {
		dispatch(appError(err));
		return Promise.reject(err);
	}
};

export const getCustomerAlarms =
	(customerId: number): ThunkResult<Promise<void>> =>
	async (dispatch) => {
		dispatch(appError(undefined));
		try {
			const req = await createRequest(dispatch, `api/v1/alarms?customerId=${customerId}&top=30&skip=0&lang=fin`);
			const res = await httpFetch(req);
			const jsonResponse = await dispatch(handleJsonResponse<IPaginatedResponse<ICustomerAlarm[]>>(res, appLogout));
			if (jsonResponse) {
				const alarms = jsonResponse.value;
				dispatch(setCustomerAlarms({customerId, alarms}));
			}
		} catch (err: unknown) {
			dispatch(appError(err));
			return Promise.reject(err);
		}
	};

export const getAlarmTexts =
	(eventId: number): ThunkResult<Promise<void>> =>
	async (dispatch) => {
		dispatch(appError(undefined));
		try {
			const headers = new Headers();
			const token = await dispatch(DoAuth());
			headers.set('Authorization', `Bearer ${token}`);
			const path = await getConfigVariable('API_SERVER', undefined, {
				useDefaults: true,
			});
			const req = new Request(`${path}/api/v1/alarms/acktexts/${eventId}`, {headers});
			const res = await httpFetch(req);
			const data = await dispatch(handleJsonResponse<IPaginatedResponse<IAlarmCustomText[]>>(res, appLogout));
			if (data?.value) {
				// we have new data
				dispatch(
					setAlarmTexts({
						eventId,
						texts: data.value,
					}),
				);
			}
		} catch (err: unknown) {
			dispatch(appError(err));
			return Promise.reject(err);
		}
	};

export const ackAlarm =
	(alarmId: number, info: {note: string; actionWas: string}): ThunkResult<Promise<void>> =>
	async (dispatch) => {
		dispatch(appError(undefined));
		try {
			const headers = new Headers();
			const token = await dispatch(DoAuth());
			headers.set('Authorization', `Bearer ${token}`);
			headers.set('Content-Type', 'application/json');
			const path = await getConfigVariable('API_SERVER', undefined, {
				useDefaults: true,
			});
			const req = new Request(`${path}/api/v1/alarms/${alarmId}/ack`, {
				headers,
				method: 'POST',
				body: JSON.stringify(info),
			});
			const res = await httpFetch(req);
			if (res.status === 423) alert('Alarm already acked');
			else await dispatch(handleJsonResponse<IAlarm>(res, appLogout));
			dispatch(getAckedAlarms());
			dispatch(getUnAckedAlarms());
		} catch (err) {
			dispatch(appError(err));
			return Promise.reject(err);
		}
	};

export const alarmAcked =
	(alarmId: number): ThunkResult<Promise<void>> =>
	async (dispatch) => {
		dispatch(removeUnackedAlarm(alarmId));
	};
