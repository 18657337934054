/* eslint-disable @typescript-eslint/no-unused-vars */
import {Action} from 'redux';
import {PUBLIC_VAPID_KEY} from '../env';
import {ReduxState, RootThunkDispatch, ThunkResult} from '../reducers';
import {appError} from '../reducers/appReducer';
import {httpFetch} from '../lib/httpFetch';
import {DoAuth} from './appActions';
import {getConfigVariable} from '../lib/getConfig';
import {Thunk} from 'yup/lib/util/types';
import {useTranslation} from 'react-i18next';

const sendSubscription =
	(subscription: PushSubscription, lang: string | null): ThunkResult<Promise<Action>> =>
	async (dispatch: RootThunkDispatch) => {
		console.log('push API sendSubscription');
		const sub = subscription.toJSON();
		// throw new Error('Push notification customer register URL is not configured!');
		const token = await dispatch(DoAuth());
		const headers = new Headers();
		headers.set('Authorization', `Bearer ${token}`);
		headers.set('Content-Type', 'application/json');
		const path = await getConfigVariable('API_SERVER', undefined, {
			useDefaults: true,
		});

		return httpFetch(`${path}/api/v1/notification/subscribe`, {
			body: JSON.stringify({
				endpoint: sub.endpoint,
				auth: sub.keys?.auth,
				key: sub.keys?.p256dh,
				lang,
			}),
			headers,
			method: 'POST',
		});
	};
export const doNotificationUnsubscribe = (): ThunkResult<Promise<void>> => async (dispatch: RootThunkDispatch) => {
	if ('serviceWorker' in navigator) {
		const registration = await navigator.serviceWorker.ready;
		if (!registration.pushManager) {
			return Promise.resolve();
		}
		const subscription = await registration.pushManager.getSubscription();
		if (subscription === null) {
			return Promise.resolve();
		}
		if (await subscription.unsubscribe()) {
			console.log('unsubscribed');
			return Promise.resolve();
		} else {
			throw new Error('failed to unsubscribe');
		}
	}
};
export const doNotificationSubscribe =
	(lang: string): ThunkResult<Promise<Action | void>> =>
	async (dispatch: RootThunkDispatch, getState: () => ReduxState) => {
		console.log('push API doNotificationSubscribe check');
		if ('serviceWorker' in navigator && Notification.permission === 'granted') {
			try {
				if (!PUBLIC_VAPID_KEY) {
					console.error('no vapid key found');
					throw new Error('no push notification VAPID provided');
				}
				console.log('Checkf if push manager unavailable.');
				const registration = await navigator.serviceWorker.ready;
				if (!registration.pushManager) {
					console.log('Push manager unavailable.');
					return Promise.resolve();
				}
				console.log('Push manager available.');
				const existedSubscription = await registration.pushManager.getSubscription();

				if (existedSubscription === null) {
					console.log('No push API subscription detected, make a request.');
					const newSubscription = await registration.pushManager.subscribe({
						applicationServerKey: PUBLIC_VAPID_KEY,
						userVisibleOnly: true,
					});
					return dispatch(sendSubscription(newSubscription, lang));
				} else {
					console.log('Existed push API subscription detected.');
					return dispatch(sendSubscription(existedSubscription, lang));
				}
			} catch (err: unknown) {
				return dispatch(appError(err));
			}
		} else {
			console.log('Not able to subscribe notifications', {navigator, permissions: Notification.permission});
			return Promise.resolve();
		}
	};
