import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {ICustomer} from '../actions/customerActions';
import {buildSliceConfig, getKey, migrateInit} from '../lib/persistUtils';
import {resetAction} from './common';
/**
 * Redux state interface
 */

interface IState {
	filteredcustomers: ICustomer[];
	_persist: any;
}

/**
 * Initial redux state
 */
const initialState: IState = {
	filteredcustomers: [],
	_persist: undefined,
};

const slice = createSlice({
	name: 'customerfilters',
	initialState,
	reducers: {
		setFilteredCustomers: (state, action: PayloadAction<ICustomer[]>) => {
			state.filteredcustomers = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAction, () => initialState);
	},
});

export const {setFilteredCustomers} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(
	slice,
	persistReducer(
		{
			key: getKey(slice.name),
			storage,
			migrate: migrateInit(initialState),
		},
		slice.reducer,
	),
);
