import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {buildSliceConfig, getKey, migrateInit} from '../lib/persistUtils';
import {IRoom} from '../models/room';

interface IState {
	rooms: IRoom[];
	_persist: any;
}

const initialState: IState = {
	rooms: [],
	_persist: undefined,
};

const slice = createSlice({
	name: 'rooms',
	initialState,
	reducers: {
		setRooms: (state, action: PayloadAction<IRoom[]>) => {
			state.rooms = action.payload;
		},
	},
});

export const {setRooms} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(
	slice,
	persistReducer(
		{
			key: getKey(slice.name),
			storage,
			migrate: migrateInit(initialState),
		},
		slice.reducer,
	),
);
