import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {IDeviceType} from '../actions/deviceTypeActions';
import {buildSliceConfig, getKey, migrateInit} from '../lib/persistUtils';

interface IState {
	avaibleDeviceTypes: IDeviceType[];
	_persist: any;
}

const initialState: IState = {
	avaibleDeviceTypes: [],
	_persist: undefined,
};

const slice = createSlice({
	name: 'availableDeviceTypes',
	initialState,
	reducers: {
		setAvailableDeviceTypes: (state, action: PayloadAction<IDeviceType[]>) => {
			state.avaibleDeviceTypes = action.payload;
		},
	},
});

export const {setAvailableDeviceTypes} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(
	slice,
	persistReducer(
		{
			key: getKey(slice.name),
			storage,
			migrate: migrateInit(initialState),
		},
		slice.reducer,
	),
);
