import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {buildSliceConfig} from '../lib/persistUtils';
import {IIncident, IIncidentType} from '../models/incident';
import {resetAction} from './common';

interface IIncidentsState {
	incidents: Record<number, IIncident[]>;
	incidentTypes?: IIncidentType[];
}

const initialState: IIncidentsState = {
	incidents: [],
};

const slice = createSlice({
	name: 'incidents',
	initialState,
	reducers: {
		setIncidents: (state, action: PayloadAction<{customerId: number; incidents: IIncident[]}>) => {
			const {customerId, incidents} = action.payload;
			state.incidents[customerId] = incidents;
		},
		setIncidentTypes: (state, action: PayloadAction<{incidentTypes: IIncidentType[]}>) => {
			const {incidentTypes} = action.payload;
			state.incidentTypes = incidentTypes;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAction, () => initialState);
	},
});

export const {setIncidents, setIncidentTypes} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(slice);
