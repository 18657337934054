import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {buildSliceConfig} from '../lib/persistUtils';
import {IBaseStatation} from '../models/baseStation';

interface IState {
	baseStations: IBaseStatation[];
	_persist: any;
}

const initialState: IState = {
	baseStations: [],
	_persist: undefined,
};

const slice = createSlice({
	name: 'baseStations',
	initialState,
	reducers: {
		setBaseStations: (state, action: PayloadAction<IBaseStatation[]>) => {
			state.baseStations = action.payload;
		},
	},
});

export const {setBaseStations} = slice.actions; // export actions

export const reducerConfig = buildSliceConfig(slice);
